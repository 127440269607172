import { observeElement, getWidget } from '@uvocore-front/utils';

/**
 *  firstTimeOfferWidget Observer
 */
/* eslint-disable */
const widgetOptions = {
  container: '#firstTimeOffer',
  customText: {
    step1: {
      inputPlaceholder: 'example@mail.com',
    },
    step2: {
      orderBtnLink: '/order.html?pages=3&couponCode=1PAGEFREE',
      successContent:
        'Details are already in your inbox. <br>Note that the discount has the expiration date.',
      errorContent: '<p>Oops, this offer is for new clients only.</p>'
    }
  },
  stepsCallback: (step) => {
    switch (step) {
      case 1:
        ga('send', 'event', {
          eventCategory: 'new-customer-discount',
          eventAction: 'discount-popup-landing',
          eventLabel: 'get discount click',
        });
        break;
      case 2:
        ga('send', 'event', {
          eventCategory: 'new-customer-discount',
          eventAction: 'discount-popup-landing',
          eventLabel: 'confirm click',
        });
        break;
      case 3:
        ga('send', 'event', {
          eventCategory: 'new-customer-discount',
          eventAction: 'discount-popup-landing',
          eventLabel: 'order with discount click',
        });
        break;
      case 4:
        ga('send', 'event', {
          eventCategory: 'new-customer-discount',
          eventAction: 'discount-popup-landing',
          eventLabel: 'not a new client',
        });
        break;
    }
  },
};

if (document.querySelector('[data-offer-widget]')) {
  observeElement({
    element: '[data-offer-widget]',
    marginValue: 200,
    callback: () => {
      getWidget({
        widgetLink: '/assets/js/firstTimeOffer.min.js',
        loader: true,
        insertLoaderTo: '[data-offer-widget]',
        loaderPosition: 'center',
        fn: () => {
          if (document.querySelector('[data-partner-offer]')) {
            widgetOptions.segment = '1 free page (partner offer)';
          }

          window.firstTimeOfferWidget(widgetOptions);
        },
      });
    },
  });
}

/* eslint-enable */
