import { observeElement } from '@uvocore-front/utils';
import Calculator from '@uvocore-front/calculator';

const OPTIONS = {
  container: '[data-widget-calculator]',
  // defaultConfigs,
  layout: {
    name: 'big',
    version: 1,
    components: {
      OrderButton: {
        text: 'Continue to order',
      },
    },
  },
};

observeElement({
  element: '[data-widget-calculator]',
  marginValue: 300,
  callback: () => {
    Calculator.init(OPTIONS);
  },
});
