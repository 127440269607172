/* Shim for deprecated uvoAutocomplete. Only modifies html layout for compatibility */
$.fn.uvoAutocomplete = $.fn.uvoAutocomplete || function() {
  return this.each(function(_, el) {
    if (el.uvoAutocompleteShimInitialized) return;
    el.uvoAutocompleteShimInitialized = true;
    var $el = $(el);
    var $wrapper = $('<div class="uvo_pref-uvoAutocomplete-wrapper" />');
    var $icon = $('<span class="ui-icon ui-icon-triangle-1-s" style="position: absolute; cursor: pointer; right: 10px; top: 0px;" />');
    $el.wrap($wrapper);
    $el.after($icon);
    $el.addClass('uvo_pref-uvoAutocomplete-input');
    $el.css({ appearance: 'none', '::-ms-expand': 'none' });
    $icon.css({ pointerEvents: 'none' });
  })
};

/* ==========================================================================
   3. Custom JS for hq-essay.com
   ========================================================================== */

$(function() {

  if(document.querySelector('#slides')) {
    // Slider in Header
    var paneTarget = $("#slides");
    var currentPos = 1;
    var cnt = 3

    function setCurrentLi () {
      $("#pagul li").removeClass("current");
      $("#li_" + currentPos).addClass("current");
    };

    function scrollToEs (a) {
      a = $(a).text();
      currentPos = a;
      goToOrder();
    };

    function goToOrder () {
      var need = "div#order_" + currentPos;
      var target = paneTarget.find(need);
      paneTarget.stop().scrollTo(target, 500, {
        onAfter: function () {
          setCurrentLi();
        }
      });
    };

    function asetClearInterval () {
      clearInterval(timer);
      if (clear) {
        clear = false;
        setTimeout(function () {
          clear = true;
          timer = setInterval(function () {
            if (currentPos <= cnt) {
              currentPos++;
            }
            if (currentPos >= cnt + 1) {
              currentPos = 1;
            }
            goToOrder();
            clear = true;
          }, 4000);
        }, 10000)
      }
      ;
      return false;
    };
    $("#li_" + currentPos).addClass("current");
    $("#pagul li").click(function () {
      asetClearInterval();
      scrollToEs(this);
    });
    $(".slide-nav-left").click(function () {
      if (currentPos > 1) {
        currentPos--;
      }
      if (currentPos < 1) {
        currentPos++;
      }
      goToOrder();
    });
    $(".slide-nav-right").click(function () {
      if (currentPos == cnt) {
        currentPos = 1;
        goToOrder();
      } else {
        if (currentPos < cnt) {
          currentPos++;
          goToOrder();
        }
      }
    });

    var clear = true;
    var timer = setInterval(function () {
      if (currentPos < cnt) {
        currentPos++;
      } else {
        if (currentPos == cnt) {
          currentPos = 1;
        }
      }
      goToOrder();
      clear = true;
    }, 4000);
    $(".slide-nav-but, #slides").hover(function () {
      asetClearInterval()
    });
  }

	//add class "selected" for item select
if ($.fn.uvoAutocomplete) {
        $('.place-your-order select').uvoAutocomplete({
		onOpen: function(e) {
			var lebelEl = this.label;
			$(this._listElement.children()).each(function() {
				if ($(this).text() == lebelEl) {
					$(this).addClass('selected')
				}
			});
		},
		sortBy: 'none'
	});
}
	//count pages
	$.fn.counts = function(options) {
			//defaultOptions
			options = $.extend({
				minusClass: 'pageskol-left',
				minimum: 1
			}, options);
			var inputCol = $(this);
			if ($(inputCol).val() <= options.minimum) {
				$('span[class=' + options.minusClass + ']').css({
					'opacity': '0.5',
					'cursor': 'default'
				});
			} else {
				$('span[class=' + options.minusClass + ']').css({
					'opacity': '1',
					'cursor': 'pointer'
				});
			}
			$(inputCol).parent().find('span').click(function() {
				if ($(this).hasClass(options.minusClass)) {
					if ($(inputCol).val() >= options.minimum) {
						var count = parseInt($(inputCol).val()) - 1;
						count = count < options.minimum ? options.minimum : count;
					} else {
						$(inputCol).val(options.minimum - 1);
						var count = parseInt($(inputCol).val()) - 1;
						count = count < options.minimum ? options.minimum : count;
					}
					if ($(inputCol).val() <= (options.minimum + 1)) {
						$('span[class=' + options.minusClass + ']').css({
							'opacity': '0.5',
							'cursor': 'default'
						});
					}
				} else {
					if ($(inputCol).val() >= options.minimum) {
						var count = parseInt($(inputCol).val()) + 1;
					} else {
						$(inputCol).val(options.minimum - 1);
						var count = parseInt($(inputCol).val()) + 1;
					}
					if ($(inputCol).val() > (options.minimum - 1)) {
						$('span[class=' + options.minusClass + ']').css({
							'opacity': '1',
							'cursor': 'pointer'
						})
					}
				}
				$(inputCol).val(count);
				$(inputCol).change();
				$(this).parents('.order-m-group').find('.pageskol-title span').html(275 *
					count);
				return false;
			});
			$(this).change(function(e) {
				var count = parseInt($(this).val())
				if (isNaN(count)) {
					count = 1;
					$(this).val(1);
				}
				$(this).parents('.order-m-group').find('.pageskol-title span').html(275 *
					count);
			});
			$(this).focusout(function(e) {
				var count = parseInt($(this).val())
				if (isNaN(count)) {
					count = 1;
					$(this).val(1);
				}
				$(this).parents('.order-m-group').find('.pageskol-title span').html(275 *
					count);
			});
			$(this).keydown(function(e) {
				if (e.which != 8 && e.which != 0 && (e.which < 37 || e.which > 57) && e
					.which != 96 && e.which != 97 && e.which != 98 && e.which != 99 && e.which !=
					100 && e.which != 101 && e.which != 102 && e.which != 103 && e.which !=
					104 && e.which != 105) {
					return false;
				}
				var key, keyChar;
				if (!e) var e = window.e;
				if (e.keyCode) key = e.keyCode;
				else if (e.which) key = e.which;
				if (key == 8) {
					return true;
				}
				if (key == 38 || key == 39 || (e.which < 48 || e.which > 57) && e.which !=
					96 && e.which != 97 && e.which != 98 && e.which != 99 && e.which !=
					100 && e.which != 101 && e.which != 102 && e.which != 103 && e.which !=
					104 && e.which != 105) {
					if (key == 40 || key == 37) {
						if ($(this).val() >= options.minimum) {
							var count = parseInt($(this).val()) - 1;
							count = count < options.minimum ? options.minimum : count;
						} else {
							$(this).val(options.minimum - 1);
							keyChar = String.fromCharCode(key);
							var count = parseInt($(this).val()) + 1;
						}
					} else {
						if ($(this).val() >= options.minimum) {
							var count = parseInt($(this).val()) + 1;
						} else {
							$(this).val(options.minimum - 1);
							var count = parseInt($(this).val()) + 1;
						}
					}
					$(this).val(count);
					$(this).parents('.order-m-group').find('.pageskol-title span').html(
						275 * count);
					if ($(inputCol).val() <= (options.minimum)) {
						$('span[class=' + options.minusClass + ']').css({
							'opacity': '0.5',
							'cursor': 'default'
						});
					} else {
						$('span[class=' + options.minusClass + ']').css({
							'opacity': '1',
							'cursor': 'pointer'
						})
					}
				}
			});
		}
		//counts
	$('#input-kol').counts();

	//toggle topmenu
	$('.toggle-menu').click(function() {
		$(this).toggleClass('active');
		if ($(this).next().find('.topmenu li').css('display') == 'none') {
			$(this).next().find('.topmenu li').show();
		} else {
			$(this).next().find('.topmenu li').hide();
		}
		return false;
	});
	if ($('.topmenu-line').length > 0) {
		var tmenu = $('.topmenu-line'),
			tmenuTop = $('.topmenu-line').offset().top;
	}

	//password reminder

	//for inquiry page
	$('.show-hide').find('.hidden').hide();
	$('#inq-done-more').click(function() {
		if (!$(this).hasClass('less')) {
			$(this).parents('.show-hide').find('.hidden').slideDown(500);
			$(this).text('Read less').addClass('less');
		} else {
			$(this).parents('.show-hide').find('.hidden').slideUp(500);
			$(this).text('Read more').removeClass('less');
		}
	});

	$('.plagcheckc aside#rightplagpanel').clone().appendTo('.plagcheckc').addClass(
		'rgt-spec-plag').attr('id', 'rightplagpanel_2');


	$('.dropdown-item').hover(function() {
		$(this).find('.services-list').stop(true, true).slideDown(400);
	}, function() {
		$(this).find('.services-list').stop(true, true).slideUp(200);
	});

	$('a[href="/order.html"]').click(function(e) {
		if ($(this).hasClass('active-order')) {
			e.preventDefault();
			$(this).off('click');
		}
	});
});

document.addEventListener("DOMContentLoaded", function () {
  if ($('[data-calc-short]').length > 0 || $('[data-calc-mini]').length > 0) {
    $.getScript('/assets/js/calc.js', function() {
      var defaultOptions = {
        deadlinesDefault: [111737, 111773, 111823, 111877, 111925],
        pagesDefault:2,
        academicLevelDefault: 2,
        paperTypesDefault: 2
      };

      if ($('[data-calc-short]').length > 0) {
        new Calc({
          ID: '46',
          calculatorType: 'ShortCalculator',
          format: 'json',
          options: {
            deafault: defaultOptions
          },
          callback: function() {
            $('.orderform-wrapper-inside .submitform').attr('disabled', false);
          }
        });
      }

      if ($('[data-calc-mini]').length > 0) {
        new Calc({
          ID: '46',
          calculatorType: 'DefaultCalculator',
          format: 'html',
          options: {
            deafault: defaultOptions
          }
        });
      }

    });
  }
});

(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '46',
      container: '.NotifyWidget-banner'
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);


(function toggleListInit() {
  if (!document.querySelector('[data-page="frequently_asked_questions"]') && !document.querySelector('[data-page="money_back_guarantee"]')) return false;


  function toggle_list() {
    $('.toggle-list .toggle-content').addClass('hide');
    $(document).on('click', '.toggle-list .toggle-link', function(){
      if($(this).next().hasClass('hide')){ $(this).next().slideDown(500); }else{ $(this).next().slideUp(500); }
      $(this).parent().toggleClass('active');
      $(this).next().toggleClass('hide');
      return false;
    });
  }

  window.addEventListener('contentRendered', toggle_list);
})();


(function (window, document) {
  function init() {
    var element = document.getElementById('CommonPage');

    if (element) {
      var pageName = element.getAttribute('data-page');
      var siteId = element.getAttribute('data-site-id');
      var policies = element.getAttribute('data-policies');
      var policyId = element.getAttribute('data-policy-id');

      let widgetOptions = {
        siteId,
        pageName,
      };

      if (policies && policyId) {
        widgetOptions = { siteId, pageName, policies, policyId };
      }

      window.commonPagesWidget.widget(element, widgetOptions);
    }
  }

  if (typeof window.commonPagesWidget !== 'undefined') {
    init();
  } else {
    var script = document.createElement('script');
    script.src = '/assets/js/commonPagesWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);
