import { observeElement } from '@uvocore-front/utils';
import Calculator from '@uvocore-front/calculator';
import getDefaultCalcOptions from '../getDefaultCalcOptions';

const defaultConfigs = getDefaultCalcOptions();

const OPTIONS = {
  container: '[data-widget-calculator-short]',
  defaultConfigs,
  layout: {
    name: 'small',
    version: 1,
    components: {
      OrderButton: {
        text: 'Continue',
      },
    },
  },
};

observeElement({
  element: '[data-widget-calculator-short]',
  marginValue: 300,
  callback: () => {
    Calculator.init(OPTIONS);
  },
});
