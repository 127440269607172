const isPageConfig =
  window.pageConfig &&
  Object.keys(window.pageConfig).length > 0 &&
  window.pageConfig.constructor === Object;

const getDefaultCalcOptions = (options = {}) => {
  const pageCalculatorConfig =
    isPageConfig && window.pageConfig.calculator !== undefined
      ? window.pageConfig.calculator
      : undefined;

  let calcOptions = options;

  calcOptions = {
    ...calcOptions.defaultConfigs,
    ...pageCalculatorConfig,
  };
  return calcOptions;
};

export default getDefaultCalcOptions;
