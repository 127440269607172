import { observeElement } from '@uvocore-front/utils';
import '../../components/special-offer/index';

/**
 *  Inline Images Observer
 */
if (document.querySelector('[data-observed-image]')) {
  observeElement({
    element: '[data-observed-image]',
    marginValue: 300,
    callback: img => {
      const src = img.getAttribute('data-src');
      const srcset = img.getAttribute('data-srcset');

      if (src) img.src = src; /* eslint-disable-line */
      if (srcset) img.srcset = srcset; /* eslint-disable-line */
    },
  });
}

/**
 *  Background Images Observer
 */
if (document.querySelector('[data-observed-bg]')) {
  observeElement({
    element: '[data-observed-bg]',
    marginValue: 300,
    callback: bgImg => {
      bgImg.classList.add('load-bg');
    },
  });
}

const ctaBtn = document.querySelector('.special-offer__cta__btn');
const targetEl = document.querySelector('.special-offer__widget-wrapper');

if (ctaBtn && targetEl) {
  ctaBtn.addEventListener('click', () => {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    targetEl.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    targetEl.querySelector('input').focus();
  });
}
